import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

export default function Hero() {
  const data = useStaticQuery(heroQuery)
  const image = data.file.childImageSharp.fluid

  return (
    <div className="home-slider margin-header">
      <div className="default-container">
        <Img fluid={image} alt="international trade image showing modes of transportation" loading="lazy" fadeIn={true} />
      </div>
    </div>
  )
}

const heroQuery = graphql`
  query heroQuery {
    file(absolutePath: { regex: "/trade-image-op70-optimized.jpg/" }) {
      childImageSharp {
        fluid(cropFocus: CENTER, maxWidth: 1568, maxHeight: 709) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
