import React from "react"

import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Tabs from "../components/Tabs"

export default function Home() {
  return (
      <Layout title="Home">
        <Hero />
        <Tabs />
      </Layout>
  )
}
