import React from "react"
import { Link } from "gatsby"

class Tabs extends React.Component {
  render() {
    return (
      <div className="filter-home segments">
        <div className="row-grid">
          <div className="col-grid-6">
            <Link to="/about/">
              <div className="content b-shadow">
                <span className="icon-book"></span>
                <h2>About Us</h2>
              </div>
            </Link>
          </div>
          <div className="col-grid-6">
            <Link to="/products/">
              <div className="content b-shadow">
                <span className="icon-shopping-bag"></span>
                <h2>Products</h2>
              </div>
            </Link>
          </div>
        </div>
        <div className="row-grid">
          <div className="col-grid-6">
            <Link to="/partners/">
              <div className="content b-shadow">
                <span className="icon-user-plus"></span>
                <h2>Partners</h2>
              </div>
            </Link>
          </div>
          <div className="col-grid-6">
            <Link to="/contact/">
              <div className="content b-shadow">
                <span className="icon-envelope"></span>
                <h2>Contact</h2>
              </div>
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

export default Tabs
